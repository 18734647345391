const lang = {
  firstSwipeItem: {

  },
  gallery: {
    title: 'Gallery'
  }
}

export default lang

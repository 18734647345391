import { createApp } from 'vue'
import App from './App.vue'

// 轮播图 css
import 'swiper/css/swiper.min.css'

// ele
// import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/display.css'
// app.use(ElementPlus)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
// i18n
import I18n from './i18n'

// 初始化 scss
import './styles/index.scss'

const app = createApp(App)
app.use(I18n)
app.use(Viewer)

app.mount('#app')

<template>
  <AsyncPage />
</template>

<script>
// 加载中页面
// 自适应字体
import changeFontSize from './mixins/changeFontSize'
// 异步组件
import { defineAsyncComponent } from 'vue'
const time = (t) => {
  return new Promise(res => {
    setTimeout(() => {
      res()
    }, t)
  })
}
const AsyncPage = defineAsyncComponent(() => {
  // 延迟 1 秒
  return new Promise((res, rej) => {
    (async function () {
      try {
        await time(1000)
        const component = await import('@/pages/mainPage')
        res(component)
      } catch (err) {
        rej(err)
      }
    })()
  })
})

export default {
  name: 'App',
  components: {
    AsyncPage
  },
  mixins: [changeFontSize],
  data() {
    return {
      y: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  height: 100%;
  overflow: hidden;
}
</style>

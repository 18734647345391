import { createI18n } from 'vue-i18n'

import zh from './config/zh-cn'
import en from './config/en-us'

const I18n = createI18n({
  locale: 'zh',
  messages: {
    en, zh
  }
})

export default I18n
